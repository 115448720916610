import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//import homeImg from '../../Assets/home_page.png';
import homeImg from '../../Assets/present3.png';
import therapistImg from '../../Assets/therapist.png';
import flowImg from '../../Assets/man.png';
import appleImg from '../../Assets/apple.png';
import googleImg from '../../Assets/google.png';
import screensImg from '../../Assets/Seeker.png';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import './Home.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

function Home() {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  const [email, setEmail] = useState('');
  const [platform, setPlatform] = useState('');

  // Event handler for email submission
  function handleEmailSubmit() {
    // Simple email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!platform) {
      alert('Please select a platform (iOS or Android).');
      return;
    }

    fetch(
      'https://seeker-backend-prod-rqicq2qnmq-uc.a.run.app/signup',
      // 'http://127.0.0.1:8000/signup',

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, platform }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setEmail(''); // Clear the email input
          setPlatform(''); // Reset the platform selection
          alert('Thank you for signing up!');
        }
      })
      .catch((error) => {
        alert('The beta will open soon.');
        console.error('There was an error!', error);
      });
  }

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={6} className="home-about-description order-md-1 order-1">
              <Fade direction="up">
                <h1 className="home-title-main">Transform Your Self</h1>
                <h1 className="home-title-sub">Optimize your Mind</h1>
              </Fade>
              <div className="app-links-container d-md-block d-none">
                <img
                  src={appleImg}
                  className="app-icon"
                  alt="Apple App Store"
                />
                <img
                  src={googleImg}
                  className="google-icon"
                  alt="Google Play Store"
                />
              </div>
            </Col>

            <Col md={6} className="home-about-image order-md-2 order-2">
              <img
                src={homeImg}
                className="appHomeScreen"
                alt="appHomeScreen"
                style={{ width: '100%', height: 'auto' }}
              />
            </Col>
          </Row>
          <Row className="waitlist-app-row order-md-3 order-3">
            <Col md={6}>
              <div className="waitlist-section">
                <div>
                  <h1 style={{ fontSize: '0.7em', fontStyle: 'italic' }}>
                    Currently in closed beta
                  </h1>
                  <input
                    type="email"
                    id="waitlistEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="waitlist-email-input"
                  />
                  <select
                    id="platformSelect"
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}
                    className="waitlist-platform-select"
                  >
                    <option value="">Select Platform</option>
                    <option value="ios">iOS</option>
                    <option value="android">Android</option>
                  </select>
                  <button
                    onClick={handleEmailSubmit}
                    className="waitlist-button"
                  >
                    Tune In
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="order-md-4 order-4">
            <Col md={12}>
              <div className="app-links-container d-md-none d-block">
                <img
                  src={appleImg}
                  className="app-icon"
                  alt="Apple App Store"
                />
                <img
                  src={googleImg}
                  className="google-icon"
                  alt="Google Play Store"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-container">
              {/* <Fade direction="up"> */}
              <p className="home-about-mission">
                We believe the future of mental health is in your hands.
              </p>
              {/* </Fade> */}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-screens">
              <img src={screensImg} className="screens" alt="Screens" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-flourish">
              <p className="home-about-mission">
                SEEKER is the most comprehensive AI built for human flourishing.
              </p>
            </Col>
          </Row>
          <Row className="home-about-doorways">
            <Col md={6}>
              <img src={flowImg} className="therapist" alt="therapist" />

              <div style={styles.bookContainer}>
                <StyledLink to="/rabbithole">Experience</StyledLink>
              </div>
              <p className="home-about-door">
                SEEKER uses advanced psychological modalities and narrative
                neuroscience to place you at the center of your own story,
                tracking your mood and offering personalized insights along the
                way. Our algorithm crafts messages to elevate your mood and
                foster self-actualization over time. Step onto the path of
                knowledge.
              </p>
            </Col>
            <Col md={6}>
              <img src={therapistImg} className="therapist" alt="therapist" />

              <div style={styles.bookContainer}>
                <StyledLink to="/platform">Integration</StyledLink>
              </div>
              <p className="home-about-door2">
                For therapists, SEEKER is a state-of-the-art educational tool at
                your fingertips, revolutionizing your practice by automating
                progress notes and treatment plans. You are able to converse
                about your clients and track their progress through the lens of
                depth psychology, parts work, and narrative therapy.
              </p>
            </Col>
          </Row>
          <Row className="home-about-container">
            <Col md={7} className="home-about-invest">
              {/* <p>Interested in investing in SEEKER</p>
              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink('https://docsend.com/view/b7zux7svb8k6ie8g')
                  }
                >
                  View our pitch deck here
                </LinkText>
              </div> */}

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink('mailto:info@seeker-flow.io?subject=Hello!')
                  }
                >
                  Get in touch
                </LinkText>
              </div>
            </Col>
          </Row>
          <Row className="home-about-container">
            <Col md={7} className="home-about-privacy">
              <p>
                SEEKER takes data privacy seriously. We only use your data to
                power your experience and deliver your personal insights. We do
                not sell your data to third parties or use your data to sell
                advertising to other companies.
              </p>
              <div style={styles.bookContainer}>
                <StyledLink to="/privacy">Privacy Policy</StyledLink>
              </div>
              <div style={styles.bookContainer}>
                <StyledLink to="/rabbithole">
                  Our intention with data
                </StyledLink>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

const styles = {
  bookContainer: {
    marginBottom: 30,
  },
};
export default Home;
