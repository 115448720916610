//just uncomment the orignal site below and also go back to the original index.js file


import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Preloader from '../src/components/Pre';
import Navbar from './components/Navbar';
import Home from './components/Home/Home';
import Research from './components/Research/Research';
import Resources from './components/Resources/Resources';
import Philosophy from './components/Philosophy/Philosophy';
import Application from './components/Application/Application';
import Footer from './components/Footer';
import Platform from './components/Platform/Platform';
// change to hashrouteer for fleek
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './components/ScrollToTop';
import RabbitHole from './components/RabbitHole/RabbitHole';
import Privacy from './components/Privacy/Privacy';

function App() {
  const [load, upadateLoad] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    const changeFavicon = () => setIsDarkMode(mq.matches);

    mq.addEventListener('change', changeFavicon);
    changeFavicon();

    return () => {
      clearTimeout(timer);
      mq.removeEventListener('change', changeFavicon);
    };
  }, []);

  return (
    <Router>
      <Helmet>
        <link
          id="favicon"
          rel="icon"
          href={isDarkMode ? `${process.env.PUBLIC_URL}/favicon-dark.svg` : `${process.env.PUBLIC_URL}/favicon-light.svg`}
        />
      </Helmet>
      <Preloader load={load} />
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/philosophy" component={Philosophy} />
          <Route exact path="/application" component={Application} />
          <Route exact path="/platform" component={Platform} />
          <Route exact path="/research" component={Research} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/rabbithole" component={RabbitHole} />
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;



// import logo from './Assets/philosophy.png';
// import './style.css';
// import './index.css';
// import { Container, Row, Col } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';
// import { Fade } from 'react-awesome-reveal';
// import Footer from './components/Footer';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// const LinkText = styled.p`
//   font-size: 16px;
//   font-weight: bold;
//   text-decoration-line: underline;
//   cursor: pointer;
//   color: white; /* Default color */

//   &:hover {
//     color: #1ce783; /* Hover color */
//   }
// `;

// const StyledLink = styled(Link)`
//   font-size: 16px;
//   font-weight: bold;
//   text-decoration-line: underline;
//   cursor: pointer;
//   color: white; /* Default color */

//   &:hover {
//     color: #1ce783; /* Hover color */
//   }
// `;


// function App() {
//   const openLink = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
  
//     <section>
//     <Container fluid className="home-about">
//       <div>
//         <Helmet>
//           <meta charSet="utf-8" />
//           <title>SEEKER</title>
//           <link rel="canonical" href="http://seeker-flow.io" />
//           <meta
//             name="description"
//             content="SEEKER Psychedelic Integration Platform"
//           />
//         </Helmet>
//       </div>

//       <Container>
       
//             <Col md={12} className="home-about-description">
//             <h1 style={{ fontSize: '3.3em' }}>  SEEKER</h1>    
//               <Fade direction="up">
//                 {/* <h1 style={{ fontSize: '2.3em' }}>Unlock your Mind</h1> */}
//                 <h1 style={{ fontSize: '2.3em', paddingTop: '40px' }}>  Optimize Your Mind <br />
//                     Expand Your Self</h1>     
              
//               </Fade>
           
             
          
//               <img
//                 src={logo}
//                 className="splashScreen"
//                 alt="brainshroom"
//               />
           
//               {/* <Fade direction="up"> */}
//               <p className="home-about-mission">
//                 At SEEKER we believe the future of mental health is in your
//                 hands. &nbsp;Our platform is about more than the symbiosis
//                 between psychedelics and AI, &nbsp;it is about our potential as
//                 a species.
//                 <br /> <br />
//               </p>
//               <h1
//                 style={{
//                   fontSize: '0.8em',
//                   fontStyle: 'italic',
//                   marginTop: '10px',
//                   color: '#1ce783',
//                 }}
//               >
//                 Thank you to our beta testers. We'll be back out in the wider
//                 world before you know it.
//               </h1>
//               <div style={styles.bookContainer}>
//                 <LinkText
//                   onClick={() =>
//                     openLink('mailto:info@seeker-flow.io?subject=Hello!')
//                   }
//                 >
//                   Get in touch
//                 </LinkText>
//               </div>
//             </Col>
         
       
       
//         </Container>
      
//         <Footer />
//       </Container>
//     </section>
//   );
// }
  
// const styles = {
//   bookContainer: {
//     marginBottom: 30,
//     marginTop: 30,
//   },
// };

// export default App;