import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import womanParkInfoImg from '../../Assets/womanParkInfo.png';
import './Research.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

const Research = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER research</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={7} className="research-about-links2">
              <Fade direction="up">
                <div>
                  <h2 style={styles.sectionContentText}>
                    In our quest for meaning, a sampling of some of the latest
                    research on Narrative, AI and Flow States.
                  </h2>
                </div>
              </Fade>
              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() => openLink('https://www.angusfletcher.co')}
                >
                  Angus Fletcher - Story Science
                </LinkText>
                <p style={styles.authorText}>
                  Professor of Story Science at Ohio State’s Project Narrative,
                  the world’s leading academic think-tank for the study of how
                  stories work.
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.psypost.org/the-heros-journey-new-psychology-research-reveals-a-pathway-to-greater-life-meaning/'
                    )
                  }
                >
                  The Hero's Journey
                </LinkText>
                <p style={styles.authorText}>
                  New psychology research reveals a pathway to greater life
                  meaning
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink('https://www.youtube.com/watch?v=GVj1KYGyesI')
                  }
                >
                  God, Infinity, The Sacred, Prayer, Relations vs. Objects
                </LinkText>
                <p style={styles.authorText}>
                  Theories of Everything with John Vearvake
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText onClick={() => openLink('link_to_book_2')}>
                  Joscha Bach Λ John Vervaeke: Mind, Idealism, Computation
                </LinkText>
                <p style={styles.authorText}>
                  Theories of Everything with Curt Jaimungal
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.amazon.com/Stealing-Fire-Maverick-Scientists-Revolutionizing/dp/0062429655'
                    )
                  }
                >
                  Zombies in Western Culture: A Twenty-First Century Crisis
                </LinkText>
                <p style={styles.authorText}>
                  by John Vearvake, Christopher Mastropietro, Filip Miscevic
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText onClick={() => openLink('link_to_book_4')}>
                  Stealing Fire: How Silicon Valley, the Navy SEALs, and
                  Maverick Scientists Are Revolutionizing the Way We Live and
                  Work
                </LinkText>
                <p style={styles.authorText}>
                  by Steven Kotler and Jamie Wheal
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() => openLink('https://www.liwc.app/help/aon')}
                >
                  Measuring Narrative
                </LinkText>
                <p style={styles.authorText}>
                  Storytelling is a topic that has fascinated scholars for
                  thousands of years. We are only now starting to understand the
                  complex psychological scaffolding that underpins the structure
                  of stories.
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.amazon.com/Immortality-Key-Secret-History-Religion/dp/B082VML6YX/ref=sr_1_1?crid=3O8I96PFONK9C&keywords=the+immortality+key&qid=1685583869&s=books&sprefix=the+immortality+key%2Cstripbooks%2C247&sr=1-1'
                    )
                  }
                >
                  The Immortality Key: The Secret History of the Religion with
                  No Name
                </LinkText>
                <p style={styles.authorText}>by Brian Muraresku</p>
              </div>
            </Col>
            <Col md={5} className="research-about-description">
              <div>
                <img
                  src={womanParkInfoImg}
                  className="therapist"
                  alt="therapist"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

const styles = {
  sectionContentText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#1ce783',
    paddingBottom: 20,
  },
  bookContainer: {
    marginBottom: 20,
  },
  linkText: {
    fontSize: 16,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  authorText: {
    fontSize: 14,
  },
};

export default Research;
