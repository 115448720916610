import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import desertImg from '../../Assets/desert.png';
import './Resources.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

const Resources = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER resources</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>

        <Container>
          <Row className="resources-about-description">
            <Col md={7} className="home-about-body">
              <div style={{ padding: 16 }}>
                <Fade direction="up">
                  <h2 style={styles.sectionContentText}>
                    Know that there are others here to aid in your
                    understanding.
                  </h2>
                </Fade>
                <div style={styles.bookContainer}>
                  <LinkText
                    onClick={() =>
                      openLink('https://firesideproject.org/?gad=1')
                    }
                  >
                    Fireside Project
                  </LinkText>
                  <p style={styles.authorText}>
                    The Psychedelic Peer Support Line provides emotional support
                    during and after psychedelic experiences.
                  </p>
                </div>
                <div style={styles.bookContainer}>
                  <LinkText
                    onClick={() => openLink('https://zendoproject.org/')}
                  >
                    Zendo Project
                  </LinkText>
                  <p style={styles.authorText}>
                    Psychedelic Peer Support Creating Communities of
                    Compassionate Care.
                  </p>
                </div>
                <div style={styles.bookContainer}>
                  <LinkText
                    onClick={() => openLink('https://www.goodtherapy.org/')}
                  >
                    Good Therapy
                  </LinkText>
                  <p style={styles.authorText}>Find a therapist</p>
                </div>
              </div>
            </Col>

            <Col md={5}>
              <img src={desertImg} className="therapist" alt="therapist" />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

const styles = {
  sectionContentText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#1ce783',
  },
  bookContainer: {
    marginBottom: 20,
  },
  authorText: {
    fontSize: 14,
  },
};

export default Resources;
