import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import therapyImg from '../../Assets/map.png';
import homeDoctor1Img from '../../Assets/homeDoctor1.png';
import gardenStoryImg from '../../Assets/gardenStory.png';
import crowdImg from '../../Assets/psy2023.png';
import './Platform.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

function Platform() {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={12} className="info-about-description">
              <Fade direction="up">
                <b className="info-about-description">
                  SEEKER provides a systemic platform for psychology clinics and
                  small practices looking to optimize the well-being of their
                  clients and employees.
                </b>
              </Fade>
              <div style={{ marginTop: '20px' }}>
                <button
                  onClick={() =>
                    (window.location.href = 'mailto:brian@seeker-flow.io')
                  }
                  style={{
                    backgroundColor: '#1ce783',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    border: 'none',
                    color: 'black',
                    fontSize: '16px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  Request Demo
                </button>
              </div>
            </Col>

            <img src={crowdImg} className="crowd" alt="psy2023" />
            <p className="caption">
              Psychedelic Science June 2023. The doctors, therapists,
              researchers and scientists working towards solutions
            </p>
          </Row>

          <Row className="info-about-container">
            <Col md={7} className="info-about-body">
              Current Mental Health services are unable to provide the depth of
              tools clients need and so we seek for more. True individualized
              therapy requires a collaboration between humans and AI. Our
              therapeutic platform fills this void, creating an equitable
              application and enabling the largest number of people to live
              healthy, integrated lives.
              <br />
              <br />
              By combining the wisdom of nature with technical innovation, we
              are able to meet this present moment. Your self report, along with
              labeled modalities and emotional valence trends enable you and
              your therapist to generate insights to help identify what is
              working for you.
              <br />
            </Col>
            <Col md={5}>
              <img src={therapyImg} className="therapist" alt="therapist" />
            </Col>
          </Row>

          <Row className="info-about-container">
            <Col md={5}>
              <img src={homeDoctor1Img} className="therapist" alt="therapist" />
            </Col>
            <Col md={7} className="info-about-body">
              Therapeutic treatments are not limited to psychoactive compounds.
              The range of existing, non-psychoactive treatment like SSRI’s have
              shown questionable efficacy and in general our population is
              subject to limited therapist availability. Our platform not only
              provides light-touch assessment model for clinicians, alleviating
              therapeutic barriers, but enables the monitoring of patient harm
              factors while generating ongoing, quantifiable data for the
              efficacy of both psychoactive and non-psychoactive treatments.
            </Col>
          </Row>
          <Row className="info-about-container">
            <Col md={7} className="info-about-body">
              Psychoactive compounds have been utilized outside of clinical
              settings for thousands of years, and as therapeutic applications
              of these molecules continue to become more widely known, their
              unsupervised use will continue to expand. However, despite the
              promise exhibited by ongoing medical research and clinical trials,
              these compounds can be associated with risks of adverse effects
              without proper therapy and integration.The lack of scaled
              therapeutic integration and self-reporting methods leaves a
              population at risk of potential ontological shock and
              destabilization. <br /> <br />
              <LinkText
                onClick={() =>
                  openLink(
                    'https://medium.com/the-shadow/hot-take-psilocybin-was-the-biggest-mistake-of-my-life-f93cf6598974'
                  )
                }
              >
                A sobering read
              </LinkText>
            </Col>
            <Col md={5}>
              <img src={gardenStoryImg} className="therapist" alt="therapist" />
            </Col>
            {/* <Col md={12} className="info-about-body">
              {' '}
              This year, millions of people will use psychedelics outside of
              supervised medical contexts, many of them for the first time.
              Seventy percent of all current traditional therapists currently
              have no patient openings while psychoactive compounds (e.g.,
              psychedelic, dissociative, and empathogenic compounds) have
              demonstrated therapeutic effect in a wide variety of clinical
              applications, including depression, anxiety, PTSD, substance
              abuse, and a variety of other conditions. A vast majority of the
              population will not be able to take part in clinical trials and
              current methods will be cost prohibitive to most people. The
              ongoing global legalization, existing lack of therapists, and the
              prohibitive costs of current roll out models combined with the
              therapeutic utility of psychedelics provides the impetus for the
              development of supportive, scaled technologies to enhance the
              safety and efficacy of these compounds. <br />
            </Col> */}
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Platform;
