import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import philosophyImg from '../../Assets/philosophy.png';
import { Fade } from 'react-awesome-reveal';
import './Philosophy.css';

function Philosophy() {
  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>
        <Container>
          <Row>
            <Col md={7} className="platform-about-description">
              <Fade direction="up">
                <h1 style={{ fontSize: '2.1em', color: '#1ce783' }}>
                  The Nature of the Future
                </h1>
                <p>
                  <b>
                    Human beings aren't the problem. <br />
                    We are the solution. <br />
                    We have a proactive role to play.
                  </b>
                </p>
              </Fade>
            </Col>

            <Col md={5} className="platform-about-description2">
              <img src={philosophyImg} className="brain" alt="brain" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="platform-about-container">
              {/* <Fade direction="up"> */}
              <p className="platform-about-mission">
                The Silicon Valley religion of the simulation is much closer to
                Brahman than people realize - Sam Altman
              </p>
              {/* </Fade> */}
            </Col>
          </Row>

          <Row md={12}>
            {/* <Fade direction="up"> */}
            <p className="platform-about-journal">
              Our universe is inherently intelligent and increasingly all things
              can be known. &nbsp;However, there is and always will be a hole at
              the center of mathematics and physics, the womb from which we all
              come, the sacred wound we all share. &nbsp;Jung said it was this
              being thrown into existence that begins our spiritual search for
              wholeness, for coming back into the one.
            </p>
            {/* </Fade> */}
          </Row>
          <Row>
            <Col md={12} className="platform-about-container">
              {/* <Fade direction="up"> */}
              <p className="platform-about-mission">
                Our whole society is not built around humanity. Everyone should
                be thinking and talking about it all the time, because aren’t we
                all sick of it? - Mona Sobhani
              </p>
              {/* </Fade> */}
            </Col>
          </Row>
          <Row>
            {/* <Fade direction="up"> */}
            <p className="platform-about-journal">
              Pharmaceutical companies pay for the broadcast waves that pacify
              eyes and minds so we don't look around at Mother Earth burning.
              &nbsp;While we must develop white hat technologies for their
              potential to change the course of civilization, we are up against
              powerful foes. &nbsp;The shared trauma of humanity is mired in
              patriarchal structures defending what is theirs.&nbsp; Moloch
              blinds us as we stare straight from the couch into looming
              disaster. &nbsp;We have lost our connection to the earth and the
              cosmos. &nbsp;Only a shifting of consciousness towards the
              matriarchal and indigenous has the potential to heal the
              individual, society and our planet. Our systems are broken and we
              are our only hope.
            </p>
            {/* </Fade> */}
          </Row>
          <Row>
            <Col md={12} className="platform-about-container">
              {/* <Fade direction="up"> */}
              <p className="platform-about-mission">
                We have a collective genius - Wade Davis
              </p>
              {/* </Fade> */}
            </Col>
          </Row>
          <Row>
            {/* <Fade direction="up"> */}
            <p className="platform-about-journal">
              We are not alone. &nbsp;This invisible college has existed for
              millennia, the mystics of all religions, the foundations of
              Eastern, Western, African and Indigenous thought revealed through
              ceremonies, songs and dialogue. &nbsp;Our elders paved the way and
              now we stand on the precipice. &nbsp;The innovators and human
              potential movement of the 1960's nearly changed the world.
              &nbsp;As AI begins to probe new realities, we must simply
              remember, put our heads down and do the work. Our healing is the
              collective healing.
            </p>
            {/* </Fade> */}
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Philosophy;
