import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './Privacy.css';

function Privacy() {
  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>
        <Container>
          <Row md={12}>
            <Col md={12} className="platform-about-description">
              <h1 style={{ fontSize: '2.1em' }}>Privacy Policy</h1>
              <p>updated 8/1/2023</p>
            </Col>
            <Col md={12} className="platform-about-jounal">
              <p>
                Welcome to SEEKER (the “Platform”). The Platform is provided and
                controlled by SEEKER Unlimited, LLC (“SEEKER”, “we” or “us”). We
                are committed to protecting and respecting your privacy.
                <br /> <br />
                Capitalized terms that are not defined in this policy have the
                meaning given to them in the Terms of Service. <br />
                <br />
                <b>What information do we collect?</b> <br /> <br />
                We collect information when you create an account or use the
                Platform, including voice recordings, photos, writings and
                edits. We also collect information you share with us from
                third-party social network providers, and technical and
                behavioral information about your use of the Platform. More
                information about the categories and sources of information is
                provided below.{' '}
                <b>
                  <br /> <br />
                  Information you choose to provide
                </b>{' '}
                <div>
                  <p>
                    For certain activities, such as when you register, upload
                    content to the Platform, or contact us directly, you may
                    provide some or all of the following information:
                  </p>
                  <ul>
                    <li>
                      Registration information, such as age, username and
                      password, language, and email, phone number or wallet
                      address
                    </li>
                    <li>
                      Profile information, such as name, bio and social media
                      account information,
                    </li>
                    <li>
                      User-generated images or video of your face and likeness
                      that you choose to create with or upload to the Platform
                      (“User Content”) for the purpose of creating a customized
                      SEEKER avatar, or for any other purposes. If you apply an
                      effect to your User Content, we will collect a version of
                      your User Content that does not include the effect.
                    </li>
                    <li>
                      User-generated voice recordings, writings, images and
                      edits that you choose to create with or upload to the
                      Platform (“User Content”) for the purpose of creating
                      content, or for any other purposes. If you apply an
                      effect, filter or AI-generated transformation to your User
                      Content, we may collect a version of your User Content
                      that does not include the effect
                    </li>
                    <li>
                      Content, including text, images, and video, found in your
                      device’s clipboard, with your permission. For example, if
                      you choose to initiate content sharing with a third-party
                      platform, or choose to paste content from the clipboard
                      into the SEEKER App, we access this information stored in
                      your clipboard in order to fulfill your request.
                    </li>
                    <li>
                      Payment information, including payment card numbers or
                      other third- party payment information (such as Stripe)
                      where required for the purpose of payment
                    </li>
                    <li>Your opt-in choices and communication preferences</li>
                    <li>
                      Information to verify an account such as proof of identity
                      or age
                    </li>
                    <li>Information in correspondence you send to us</li>
                    <li>
                      Information you share through surveys or your
                      participation in challenges, sweepstakes, or contests such
                      as your gender, age, likeness, and preferences.
                    </li>
                  </ul>
                </div>
                <br />
                <b>Information we obtain from other sources</b> <br /> <br /> We
                may receive the information described in this Privacy Policy
                from other sources, such as: <br />
                <br />
                <b>Social Media and Login Services. </b>
                If you choose to link or sign up using a third-party social
                network or login service (such as Facebook, Twitter, Instagram,
                or Google), we may collect information from these services,
                including your contact lists for these services and information
                relating to your use of the Platform in relation to these
                services. If you link your SEEKER account to another service, we
                may receive information about your use of that service.
                <br /> <br />
                <b>Third-Party Services. </b>
                We may collect information about you from third- party services,
                such as advertising partners, data providers, and analytics
                providers. <br /> <br /> <b>Other Sources. </b>
                We may collect information about you from other publicly
                available sources. <br /> <br /> <br />
                <b>Information we collect automatically</b>
                <br /> <br /> We automatically collect certain information from
                you when you use the Platform, including internet or other
                network activity information such as your IP address,
                geolocation-related data (as described below), unique device
                identifiers, browsing and search history (including content you
                have viewed in the Platform), and Cookies (as defined below).
                <br /> <br />
                <b>Usage Information </b> <br /> <br />
                We collect information regarding your use of the Platform and
                any other User Content that you generate through or upload to
                our Platform. Aggregating and/or anonymizing Personal
                Information We may aggregate and/or anonymize Personal
                Information so that it will no longer be considered Personal
                Information. We do so to generate other data for our use, which
                we may use and disclose for any purpose, as it no longer
                identifies you or any other individual. <br /> <br />{' '}
                <b>Device Information </b> <br /> <br />
                We collect certain information about the device you use to
                access the Platform, such as your IP address, user agent, mobile
                carrier, time zone settings, identifiers for advertising
                purposes, model of your device, the device system, network type,
                device IDs, your screen resolution and operating system, app and
                file names and types, keystroke patterns or rhythms, battery
                state, audio settings and connected audio devices. Where you
                log-in from multiple devices, we will be able to use your
                profile information to identify your activity across devices. We
                may also associate you with information collected from devices
                other than those you use to log- in to the Platform. <br />{' '}
                <br />
                <b>Location data</b> <br /> <br /> We collect information about
                your approximate location, including location information based
                on your SIM card and/or IP address. With your permission, we may
                also collect precise location data (such as GPS). <br /> <br />
                <b>Image and Audio Information </b> <br /> <br />
                We may collect biometric identifiers and biometric information
                as defined under US laws, such as faceprints and voiceprints,
                from your User Content. Where required by law, we will seek any
                required permissions from you prior to any such collection.
                <br /> <br /> <b>Metadata </b> <br /> <br />
                When you upload or create User Content, you automatically upload
                certain metadata that is connected to the User Content. Metadata
                describes other data and provides information about your User
                Content that will not always be evident to the viewer. In
                connection with your User Content the metadata can describe how,
                when, where, and by whom the piece of User Content was created,
                collected, or modified and how that content is formatted. It
                also includes information, such as your account name, that
                enables other users to trace back the User Content to your user
                account. Additionally, metadata includes data that you choose to
                provide with your User Content, e.g. any hashtags used to mark
                keywords to the video.
                <br /> <br />
                <b>Cookies </b>
                <br /> <br /> We and our service providers and business partners
                use cookies and other similar technologies (e.g. web beacons,
                flash cookies, etc.) (“Cookies”) to automatically collect
                information, measure and analyze which web pages and
                advertisements you click on and how you use the Platform,
                enhance your experience using the Platform, improve the
                Platform, provide you with advertising on the Platform and
                elsewhere across your devices, and measure the effectiveness of
                advertisements. Cookies enable the Platform to provide certain
                features and functionality. Web beacons are very small images or
                small pieces of data embedded in images, also known as “pixel
                tags” or “clear GIFs,” that can recognize Cookies, the time and
                date a page is viewed, a description of the page where the pixel
                tag is placed, and similar information from your computer or
                device. To learn how to disable Cookies, see the “Your choices”
                section below. <br /> <br /> We and our service providers and
                business partners may link your contact or account information
                with your activity on and off our Platform across all your
                devices, using your email or other log-in or device information.
                Our service providers and business partners may use this
                information to display advertisements on our Platform and
                elsewhere online and across your devices tailored to your
                interests, preferences, and characteristics. We are not
                responsible for the privacy practices of these service providers
                and business partners, and the information practices of these
                service providers and business partners are not covered by this
                Privacy Policy. <br /> <br /> We may aggregate or de-identify
                the information described above. Aggregated or de-identified
                data is not subject to this Privacy Policy. <br /> <br /> <br />{' '}
                <b>Information we do not collect</b> <br /> <br /> We do not
                record or store any audio or video from your device microphone
                or webcam without your explicit permission.
                <br /> <br /> <b>How we use your information </b> <br /> <br />{' '}
                As explained below, we use your information to improve, support
                and administer the Platform, to allow you to use its
                functionalities, and to fulfill and enforce our Terms of
                Service. We may also use your information to, among other
                things, show you suggestions, promote the Platform, and
                customize your ad experience.
                <br /> <br /> We generally use the information we collect:{' '}
                <div>
                  {' '}
                  <ul>
                    <li>
                      To fulfill requests for products, services, Platform
                      functionality, support and information for internal
                      operations, including troubleshooting, data analysis,
                      testing, research, statistical, and survey purposes and to
                      solicit your feedback
                    </li>
                    <li>
                      To customize and improve the content you see when you use
                      the Platform. For example, we may provide you with
                      services based on the country settings you have chosen or
                      show you content that is similar to content that you liked
                      or interacted with
                    </li>
                    <li>
                      To send promotional materials from us or on behalf of our
                      affiliates and trusted third parties
                    </li>
                    <li>
                      To QA, improve and develop our Platform and conduct
                      product development
                    </li>
                    <li>To make customized suggestions</li>
                    <li>
                      To support the social functions of the Platform, including
                      to permit you and other users to connect with each other
                      through the Platform and for you and other users to share,
                      download, and otherwise interact with User Content posted
                      through the Platform
                    </li>
                    <li>
                      To use User Content as part of our advertising and
                      marketing campaigns to promote the Platform
                    </li>
                    <li>
                      To understand how you use the Platform, including across
                      your devices
                    </li>
                    <li>
                      To infer additional information about you, such as your
                      age, gender, and interests
                    </li>
                    <li>
                      To help us detect abuse, fraud, and illegal activity on
                      the Platform
                    </li>
                    <li>
                      To prove your identity in order to use certain features,
                      such as livestream or verified accounts, or when you apply
                      for certain Account types, ensure that you are old enough
                      to use the Platform, or in other instances where
                      verification may be required
                    </li>
                    <li>
                      To communicate with you, including to notify you about
                      changes in our services
                    </li>
                    <li>
                      To announce you as a winner of our contest, sweepstakes,
                      or promotions if permitted by the promotion rule, and to
                      send you any applicable prizes
                    </li>
                    <li>To enforce our terms, conditions, and policies</li>
                    <li>
                      Consistent with your permissions, to provide you with
                      location-based services, such as advertising and other
                      personalized content
                    </li>
                    <li>To inform our algorithms</li>
                    <li>
                      To combine all the information we collect or receive about
                      you for any of the foregoing purposes
                    </li>
                    <li>
                      For any other purposes disclosed to you at the time we
                      collect your information or pursuant to your consent.
                    </li>
                    <li>
                      To facilitate sales, promotion, and purchases of goods and
                      services and to provide user support
                    </li>
                  </ul>
                </div>
                <br /> <b>How we share your information</b>
                <br /> <br /> We are committed to maintaining your trust, and
                while SEEKER does not sell personal information to third
                parties, we want you to understand when and with whom we may
                share the information we collect for business purposes.
                <br /> <br /> <b>Service Providers and Business Partners </b>
                <br /> <br />
                <div>
                  <p>
                    We share the categories of personal information listed above
                    with service providers and business partners to help us
                    perform business operations and for business purposes,
                    including core product functionality, research, payment
                    processing and transaction fulfillment, database
                    maintenance, administering contests and special offers,
                    technology services, deliveries, sending communications,
                    advertising, analytics, measurement, data storage and
                    hosting, disaster recovery, search engine optimization,
                    marketing, and data processing. These service providers and
                    business partners may include:
                  </p>
                  <ul>
                    <li>
                      Payment processors and transaction fulfillment providers,
                      who may receive the information you choose to provide, the
                      information we obtain from other sources, and the
                      information we collect automatically but who do not
                      receive your message data.
                    </li>
                    <li>
                      Customer and technical support providers, who may receive
                      the information you choose to provide, the information we
                      obtain from other sources, and the information we collect
                      automatically.
                    </li>
                    <li>
                      Research providers, who may receive the information you
                      choose to provide, the information we obtain from other
                      sources, and the information we collect automatically but
                      would not receive your payment information or message
                      data.
                    </li>
                    <li>
                      Cloud providers, who may receive the information you
                      choose to provide, the information we obtain from other
                      sources, and the information we collect automatically.
                    </li>
                    <li>
                      Third-party AI service providers, who have their own terms
                      of service, which we are unable to influence and that may
                      change without notice.
                    </li>
                    <li>
                      Advertising, marketing, and analytics vendors, who may
                      receive the information you choose to provide, the
                      information we obtain from other sources, and the
                      information we collect automatically but would not receive
                      your payment information or message data.
                    </li>
                  </ul>
                </div>
                <br />{' '}
                <b>
                  In Connection with a Sale, Merger, or Other Business Transfer{' '}
                </b>
                <br /> <br />
                We may share all of the information we collect in connection
                with a substantial corporate transaction, such as the sale of a
                website, a merger, consolidation, asset sales, or in the
                unlikely event of bankruptcy.
                <br /> <br /> <b>For Legal Reasons </b>
                <br /> <br />
                We may disclose any of the information we collect to respond to
                subpoenas, court orders, legal process, law enforcement
                requests, legal claims, or government inquiries, and to protect
                and defend the rights, interests, safety, and security of
                Present Communications, Inc., the Platform, our affiliates,
                users, or the public. We may also share any of the information
                we collect to enforce any terms applicable to the Platform, to
                exercise or defend any legal claims, and comply with any
                applicable law.
                <br /> <br /> <b>With Your Consent </b>
                <br /> <br />
                We may share your information for other purposes pursuant to
                your consent or at your direction. If you access third-party
                services, such as Facebook, Google, or Twitter, to login to the
                Platform or to share information about your usage on the
                Platform with others, these third-party services may be able to
                collect information about you, including information about your
                activity on the Platform, and they may notify your connections
                on the third-party services about your use of the Platform, in
                accordance with their privacy policies. If you choose to allow a
                third-party service to access your account, we will share
                certain information about you with the third party. Depending on
                the permissions you grant, the third party may be able to obtain
                your account information and other information you choose to
                provide. If you choose to engage in public activities on the
                Platform, you should be aware that any information you share may
                be read, collected, or used by other users. You should use
                caution in disclosing personal information while using the
                Platform. We are not responsible for the information you choose
                to submit.
                <br /> <br />
                <b>Your Rights </b>
                <br /> <br />
                You may submit a request to access or delete the information we
                have collected about you by sending your request to us at the
                email or physical address provided in the Contact section at the
                bottom of this policy. You may be entitled, in accordance with
                applicable law, to submit a request through an authorized agent.
                To designate an authorized agent to exercise choices on your
                behalf, please provide evidence that you have given such agent
                power of attorney or that the agent otherwise has valid written
                authority to submit requests to exercise rights on your behalf.
                We will respond to your request consistent with applicable law
                and subject to proper verification. We will verify your request
                by asking you to send it from the email address associated with
                your account or to provide information necessary to verify your
                account. We do not discriminate based on the exercise of any
                privacy rights that you might have.
                <br /> <br />
                <b>Your Choices </b>
                <br /> <br />
                You may be able to control some of the information we collect by
                adjusting your browser settings to refuse or disable Cookies.
                Because each browser is different, please consult the
                instructions provided by your browser. Please note that you may
                need to take additional steps to refuse or disable certain types
                of Cookies. In addition, your choice to disable cookies is
                specific to the particular browser or device that you are using
                when you disable cookies, so you may need to separately disable
                cookies for each type of browser or device. If you choose to
                refuse, disable, or delete Cookies, some of the functionality of
                the Platform may no longer be available to you. Without this
                information, we are not able to provide you with all the
                requested services. Depending on the version of the Platform you
                are using, you may be able to decide whether you see certain
                types of interest-based advertising on the Platform by visiting
                your in-app settings. You may be able to manage third-party
                advertising preferences for some of the third parties we work
                with to serve advertising across the Internet by using the
                choices available at
                https://www.networkadvertising.org/managing/opt_out.asp and
                https://www.aboutads.info/choices. Your device may have controls
                that determine what information we collect or how we can use
                that information. For example, you can control whether we can
                use your mobile advertising identifier for advertising through
                settings on your Apple and Android devices. You can opt out of
                marketing or advertising emails by using the “unsubscribe” link
                or mechanism noted in marketing or advertising emails. If you
                previously chose to share precise location information, you can
                prevent your device from sharing precise location information
                (e.g. GPS location information) with the Platform at any time
                through your device’s operating system settings. If you have
                registered for an account, you may access, review, and update
                certain personal information that you have provided to us by
                logging into your account and using available features and
                functionalities. Some browsers transmit “do-not-track” signals
                to websites. Because of differences in how browsers incorporate
                and activate this feature, we currently do not take action in
                response to these signals.
                <br /> <br />
                <b>Security and Storage </b>
                <br /> <br />
                We use reasonable measures to help protect information from
                loss, theft, misuse and unauthorized access, disclosure,
                alteration, and destruction. You should understand that no data
                storage system or transmission of data over the Internet or any
                other public network can be guaranteed to be 100 percent secure.
                Please note that information collected by third parties may not
                have the same security protections as information you submit to
                us, and we are not responsible for protecting the security of
                such information. SEEKER may transmit your data to its servers
                or data centers outside of the United States for storage and/or
                processing. Third parties with whom SEEKER may share your data
                as described herein may be located outside of the United States.
                <br /> <br />
                <b>No Children </b>
                <br /> <br /> The Platform is not directed at children under the
                age of 18. You must be at least 18 years of age to use the
                Service. If we become aware that personal information has been
                collected on the Platform from a person under the age of 18 we
                will delete this information and terminate the person’s account.
                If you believe that we have collected information from a child
                under the age of 18 on the Platform, contact us at:
                info@seeker-flow.io
                <br /> <br />
                <b>Other Rights </b>
                <br /> <br />
                <b>Sharing for Direct Marketing Purposes (Shine the Light) </b>
                <br /> <br />
                If you are a California resident, once a calendar year, you may
                be entitled to obtain information about personal information
                that we shared, if any, with other businesses for their own
                direct marketing uses. If applicable, this information would
                include the categories of customer information, as well as the
                names and addresses of those businesses with which we shared
                customer information for the immediately prior calendar year. To
                submit a request, contact us at: info@seeker-flow.io
                <br /> <br />
                <b>Changes </b>
                <br /> <br />
                We may update this Privacy Policy from time to time. When we
                update the Privacy Policy, we will notify you by updating the
                “Last Updated” date at the top of this policy and posting the
                new Privacy Policy and providing any other notice required by
                applicable law. We recommend that you review the Privacy Policy
                each time you visit the Platform to stay informed of our privacy
                practices. <b>Contact </b>
                <br /> <br /> Questions, comments and requests regarding this
                policy should be addressed to: Mailing Address: SEEKER
                Unlimited, LLC., Attn: SEEKER Legal, 2650 W. Belden Ave.,
                Chicago, IL 60647 Email Address: info@seeker-flow.io
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Privacy;
